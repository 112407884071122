<template>
  <div>
    <SchoolEmployeeAwayApplicationTable
      :items="items"
      :is-busy="isBusyStore"
      :pagination="pagination"
    ></SchoolEmployeeAwayApplicationTable>

    <div class="d-flex justify-content-end" v-if="items.length">
      <b-pagination
        size="lg"
        @change="changePage"
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SchoolEmployeeAwayApplicationTable from "@/modules/school/components/management/away/application/SchoolEmployeeAwayApplicationTable";

export default {
  name: "SchoolTeacherAwayApplication",
  components: { SchoolEmployeeAwayApplicationTable },
  data() {
    return {
      isBusy: false,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchTeacherAwayApplicationApprovalList")
        .finally(() => (this.isBusy = false));
    },
    changePage(page) {
      this.$store.commit("setTeacherAwayApplicationApprovalListPage", page);
      this.fetch();
    },
  },
  computed: {
    ...mapGetters({
      items: "getTeacherAwayApplicationApprovalList",
      pagination: "getTeacherAwayApplicationApprovalPagination",
      isBusyStore: "setTeacherAwayApplicationApprovalBusy",
    }),
  },
};
</script>

<style scoped></style>
